#burger-wrapper {
  width: 35px;
  height: 35px;
  padding: 8px;
  display: flex;
  //position: fixed;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //top: 10px;
  //right: 10px;
  cursor: pointer;
  background-color: #000;
  border-radius: 50%;

  .burger-inner {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
  }

  body.lightbox-open & {
	display: none;
  }

  z-index: 10000;

  span {
	display: block;
	//position: absolute;
	height: 2px;
	width: 100%;
	background: #000;
	transform-origin: center;

	body.bg-white & {
	  background-color: #FFF;
	}

	position: absolute;
	opacity: 1;
	transform: rotate(0deg);
	transition: 0.2s ease-in-out;


	&:nth-child(1) {
	  width: 50%;
	  left: 0;
	  top: 2px;

	}

	&:nth-child(2) {
	  left: 0;
	  top: 8px;
	}

	&:nth-child(3) {
	  width: 50%;
	  left: auto;
	  right: 0;
	  top: 14px;
	}

	body.menu-open & {
	  &:nth-child(1) {
		transform: rotate(45deg);

		width: 100%;
		top: 8px;

	  }

	  &:nth-child(2) {
		transform: rotate(-45deg);
	  }

	  &:nth-child(3) {
		opacity: 0;
		transform: rotate(45deg);
	  }
	}

  }

  body:not(.mobile-detected) & {
	&:hover #burger-circle {
	  background-color: #000;
	  border: none;
	}

	&:hover #menu-burger span {
	  background-color: #FFF;
	  width: 100% !important;
	}
  }
}