#groupe {

  .visuel {
	margin: 20px 0 0 0;
	text-align: center;
	@include media-breakpoint-up(md) {

	  margin: 80px 0 20px 0;
	}

	img {
	  width: 200px;
	  @include media-breakpoint-up(md) {
		width: 350px;
	  }
	  @include media-breakpoint-up(lg) {
		width: 500px;
	  }
	}
  }

  .separateur-logo {
	width: 1px;
	height: 60px;
	content: '';
	margin: 0 auto 40px auto;
	position: relative;

	.trait {
	  width: 100%;
	  background: #000;
	  content: '';
	  position: absolute;
	  left: 0;
	  top: 0;
	  height: 60px;
	}
  }

  .separateur {
	position: relative;
	bottom: auto;
	//	margin-top: 25px;
  }

  @include media-breakpoint-up(lg) {
	.content-group {
	  max-width: 600px;
	  margin: 0 auto;
	}
  }
}
