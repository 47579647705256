.title-h1 {
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  @include media-breakpoint-up(md) {
	margin-bottom: 2rem;
	font-size: 1.6rem;
  }
  @include media-breakpoint-up(lg) {
	margin-bottom: 3rem;
	font-size: 1.8rem;
  }
}

.title-h3 {
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2rem;

  @include media-breakpoint-up(md) {
	font-size: 1.7rem;
  }
  @include media-breakpoint-up(lg) {
	text-align: left;
  }
}

.title-2 {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 2.6rem;
  text-align: center;
  position: relative;
  padding-bottom: 10px;

  span {
	content: "";
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 80px;
	height: 3px;
	background: red;
	transform-origin: center;
	transform: translate(-50%, 0) scaleX(1);

  }
}

#administration .title-2 span {
  background: #6D5C51;
}

#immobilier .title-2 span {
  background: #EA501E;
}

#sport .title-2 span {
  background: #E3DE00;
}

#evenementiel .title-2 span {
  background: #EB4985;
}

#industriel .title-2 span {
  background: #BC163E;
}

#alimentaire .title-2 span {
  background: #E96750;
}


.font-bold {
  font-weight: bold;
}

.font-thin {
  font-weight: bold;
}

.font-extrabold {
  font-weight: 900;
}

.font-italic {
  font-style: italic;
}

.font-trajan {
  font-family: 'Cinzel', serif;
}

p {
  @include media-breakpoint-up(md) {
	font-size: 1.1rem;
  }
}
