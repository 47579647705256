#associes {

  .fp-scroller {
	min-height: 100vh;
  }
}

.associe {
  @include media-breakpoint-up(lg) {
	display: flex;
	align-items: start;
  }
  @include media-breakpoint-up(lg) {
	.words {
	  width: 100%;
	}
  }

  .wording {
	position: relative;
	@include media-breakpoint-up(lg) {
	  // width: 60%;
	  display: flex;
	  align-items: flex-end;
	}

	.word {
	  position: absolute;
	  z-index: 2;
	  @include media-breakpoint-up(md) {
		font-size: 1.8rem;
	  }
	  @include media-breakpoint-up(lg) {
		position: relative;
		display: block;
		text-align: right;
		padding-right: 45px;
	  }

	  &:nth-child(1) {
		left: 5%;
		top: 10%;
		@include media-breakpoint-up(lg) {
		  inset: 0;
		}
	  }

	  &:nth-child(2) {
		right: 0;
		top: 40%;
		@include media-breakpoint-up(lg) {
		  inset: 0;
		}
	  }

	  &:nth-child(3) {
		left: -5px;
		bottom: 30%;
		@include media-breakpoint-up(lg) {
		  inset: 0;
		}
	  }
	}

	.bloc-visuel-associes {
	  z-index: 1;
	  margin: 50px auto;
	  position: relative;
	  overflow: hidden;
	  width: 50vw;

	  @include media-breakpoint-up(lg) {
		width: 400px;
		margin: 0;
	  }


	  .bg {
		position: absolute;
		top: -10px;
		bottom: -10px;
		right: -10px;
		left: -10px;
		content: '';
		transform-origin: left center;
		background-color: #5c636a;
	  }
	}


  }

  .info-associe {
	@include media-breakpoint-up(lg) {
	  width: 460px;
	  padding-left: 45px;

	  .title-h3 {
		margin-top: 5rem;
	  }
	}
  }
}