#activites {

  .fp-scroller {
	min-height: 100vh;
  }


}

.bloc-content-activite {
  @include media-breakpoint-up(lg) {
	width: 90vw;
	max-width: 960px;
	display: flex;
	margin-top: 2rem;


	.bloc-visuel {
	  width: 45%;
	  margin: .5rem 0 0 0;
	}
	.container {
	  width: 55%;
	  padding-left: 2rem;
	  margin-right: 0;
	  padding-right: 0;
	}
  }
}

.clients {
  .item {
	width: 90px;
	height: 90px;
	background: #FFF;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	margin-right: 10px;
	background-size: cover;
	background-position: center;
	border: 1px solid #000;

	&.gefacor {
	  background-image: url("../images/clients/GefacorLogoRond.jpg");
	}

	&.oplo {
	  background-image: url("../images/clients/OploLogoRond.jpg");
	}

	&.immospirit {
	  background-image: url("../images/clients/ImmoLogoRond.jpg");
	}

	&.homespirit {
	  background-image: url("../images/clients/HomeLogoRond.jpg");
	}

	&.sportspirit {
	  background-image: url("../images/clients/SportSpiritLogoRond.jpg");
	}

	&.openelle {
	  background-image: url("../images/clients/OpenWomenLogoRond.jpg");
	}

	&.openjunior {
	  background-image: url("../images/clients/OpenJuniorLogoRond.jpg");
	}

	&.plastpack {
	  background-image: url("../images/clients/PlatPackRond.jpg");
	}

	&.celt {
	  background-image: url("../images/clients/CeltInspiLogoRond.jpg");
	}
  }
}
