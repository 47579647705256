#home {
  video {
	width: 100%;

	//1920 × 1080
	@include media-breakpoint-up(lg) {
	  margin: 0 auto;
	  --height: 60vh;
	  height: var(--height);
	  width: calc(1920 * var(--height) / 1080);
	  display: block;
	}

  }

  .intro {
	margin: 50px auto 0 auto;
	max-width: 700px;
	text-align: center;
  }

  .separateur {
	//bottom: -20px;
	@include media-breakpoint-up(lg) {
	  &:after, &:before {
		width: 300px;
	  }
	}
  }
}