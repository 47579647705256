body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

img {
  width: 100%;
}

p {
  font-size: .9rem;
}

.section-padding {
  padding: 70px 0 50px 0;

  @include media-breakpoint-up(md) {
	padding: 80px 0;
  }

}

#fullpage {
  transition-delay: 1s !important;

  body.fp-viewing-section-groupe & {
	transition-delay: 1s !important;
  }

}


.bloc-visuel {
  margin: 50px 0;
  position: relative;
  overflow: hidden;

  .bg {
	position: absolute;
	top: -10px;
	bottom: -10px;
	right: -10px;
	left: -10px;
	content: '';
	transform-origin: left center;
  }
}

.big-visuel {
  position: relative;
  display: inline-block;
}

.section {
  position: relative;

  .inner {
	position: relative;
	z-index: 2;
	min-height: calc(100vh - 120px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@include media-breakpoint-up(md) {
	  min-height: calc(100vh - 160px);
	}


  }


}


.separateur {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  z-index: 2;

  margin: 25px 0 0 0;
  text-align: center;

  &:after, &:before {
	content: '';
	width: 30vw;
	position: absolute;
	height: 1px;
	background: #000000;
	top: 50%;
	left: 50%;
  }

  &:after {
	transform: translate(20px, 0);
  }

  &:before {
	transform: translate(calc(-100% - 20px), 0);
  }

  img {
	width: 25px;
	height: 25px;
	cursor: pointer;
  }
}


.separateur-only {

  width: 100%;
  position: absolute;
  bottom: 50px;
  top: auto;
  left: 0;
  text-align: center;
  z-index: 2;

  &.relative {
	@include media-breakpoint-down(md) {

	  position: relative;
	  bottom: auto;
	  top: -15px;
	  padding-bottom: 35px;
	}
  }

  a {
	display: inline-block;
	padding: 15px;

	img {
	  width: 25px;
	  height: 25px;
	  cursor: pointer;
	}
  }


  &.reverse {
	img {
	  transform: rotate(180deg);
	}
  }
}

.iScrollVerticalScrollbar {
  display: none;
}

main {
  z-index: 2;
}

.backgrounds {
  position: fixed;
  inset: 0;
  z-index: 1;
  display: none;

  div {
	position: absolute;
	inset: 0;
	transform-origin: bottom center;
	transform: scaleY(0);

  }
}

#background-fade {
  position: fixed;
  inset: 0;
  z-index: 1;
}

.lignes-wagon {
  margin-top: 50px;
  position: relative;
  width: 150vw;
  left: -15vw;
  overflow: hidden;

  body.rotate-wagons & {
	transform: rotate(-15deg);

	@include media-breakpoint-up(lg) {
	  transform: rotate(-10deg);
	  padding-top: 6vw;
	}
  }


  .ligne {
	font-size: 11vw;
	height: 14vw;
	font-weight: 600;
	line-height: 1;
	padding: 0.5rem 0;
	text-transform: uppercase;
	white-space: nowrap;
	position: relative;

	@include media-breakpoint-up(lg) {
	  font-size: 125px;
	  height: 140px;

	  &:nth-child(5), &:nth-child(6) {
		display: none;
	  }
	}

	.wagons {
	  position: absolute;
	  min-width: 100%; /* au minimum la largeur du conteneur */
	}

	.wagon-1, .wagon-2 {
	  display: inline-block;
	  position: relative;
	  white-space: nowrap;
	  top: 0;
	}

	.wagon-1 {
	  animation: defilement 15s infinite linear;

	  &.reverse {
		animation: defilement-reverse 15s infinite linear;
	  }
	}

	.wagon-2 {
	  position: absolute;
	  top: 0;
	  left: 0;
	  animation: defilement2 15s infinite linear;

	  &.reverse {
		animation: defilement-reverse2 15s infinite linear;
	  }
	}

	.item {
	  color: #535353;
	  display: inline-block;
	  padding: 0 20px;
	  white-space: nowrap;


	}
  }
}

@keyframes defilement {
  0% {
	left: 0;
  }
  100% {
	left: -100%;
  }
}

@keyframes defilement2 {
  0% {
	left: 100%;
  }
  100% {
	left: 0;
  }
}

@keyframes defilement-reverse {
  0% {
	left: -100%;
  }
  100% {
	left: 0;
  }
}

@keyframes defilement-reverse2 {
  0% {
	left: 0;
  }
  100% {
	left: 100%;
  }
}


.block-line {
  margin: 20px 0;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: top;

  .line {
	height: 12vh;
	width: 1px;
	background: #000;
  }
}


.items-listing {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  @include media-breakpoint-up(md) {
	max-width: 800px;
  }


  a {
	width: 50%;
	text-align: center;
	margin: 5px 0;
	font-size: 1.1rem;
	line-height: 1.6rem;
	cursor: pointer;
	text-decoration: none;

	&:hover {
	  &.color-black-60 {
		color: rgba(0, 0, 0, 0.6);
	  }

	  &.color-black-80 {
		color: rgba(0, 0, 0, 0.8);
	  }

	  &.color-black-100 {
		color: rgba(0, 0, 0, 1);
	  }
	}

	@include media-breakpoint-up(md) {
	  font-size: 2rem;
	  line-height: 3rem;
	}


	&.w-100 {
	  width: 100%;
	}

	&.w-25 {
	  width: 25%;
	}

	&.w-30 {
	  width: 30%;
	}

	&.text-left {
	  text-align: left;
	  padding-left: 15px;
	}

	&.text-right {
	  padding-right: 15px;
	  text-align: right;
	}

  }

  .names {
	width: 100%;
	display: flex;
	align-items: baseline;
	justify-content: space-around;

	a {
	  width: auto;
	  margin: 0 3px;
	}
  }

}

