#menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 350px;
  height: 100%;
  z-index: 9998;
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
  transition: all .5s ease;
  display: none;

  body.menu-open & {
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
  }
}

#menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: none;
  opacity: 0;
  transition: opacity .5s ease;
  background-color: #000;
  padding: 70px 30px;


  @include media-breakpoint-up(md) {
	padding: 70px 50px;
	width: 350px;
	left: auto;
	right: 0;
  }

  body.menu-open & {
	opacity: 1;
  }


  > ul {
	margin: 0;
	padding: 0;


	> li {
	  margin: 0 0 20px 0;
	  padding: 0;
	  list-style: none;
	  opacity: 0;
	  transform: translateX(10px);


	  body.menu-open & {
		opacity: 1;
		transform: translateX(0);
	  }


	  &:last-child {
		margin-bottom: 0;
	  }

	  > a {
		display: inline-block;
		color: #FFF;
		font-size: 1.7rem;
		text-decoration: none;
		font-weight: 700;
		transition: all .3s ease;

	  }

	  @include media-breakpoint-up(md) {
		&:hover {
		  > a {
			//color: $primary-dark;
			//color: $color-dark;
			letter-spacing: 1px;
		  }
		}
	  }


	  @for $i from 0 through 12 {
		&:nth-child(#{$i + 1}) {
		  transition: opacity .4s ease #{$i*0.2}s, transform .4s ease #{$i*0.2}s;
		}
	  }


	  > ul {
		margin: 0;
		padding: 0;
		list-style: none;


		li {

		  margin: 0 0 7px 0;
		  padding-left: 20px;

		  &:last-child {
			margin-bottom: 0;
		  }

		  a {
			font-weight: 300;
			font-size: 1rem;
			color: #C8C8C8;
			text-decoration: none;
		  }

		  @include media-breakpoint-up(md) {
			&:hover {
			  > a {
				letter-spacing: 1px;
			  }
			}
		  }

		}
	  }
	}
  }


}

body.menu-open {
  overflow: hidden;
}