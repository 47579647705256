.color-brown {
  color: $brown;
}

.color-brown-dark {
  color: $brown-dark;
}

.color-red {
  color: $red;
}

.color-duck {
  color: $duck;
}

.color-blue {
  color: $blue;
}

.color-green {
  color: $green;
}

.color-white {
  color: #FFF;
}

.bg-brown {
  background-color: $brown;
}

.bg-brown-dark {
  background-color: $brown-dark;
}

.bg-red {
  background-color: $red;
}

.bg-duck {
  background-color: $duck;
}

.bg-blue {
  background-color: $blue;
}

.bg-green {
  background-color: $green;
}

.color-black-100 {
  color: #000;
}

.color-black-80 {
  color: rgba(0, 0, 0, 0.8);
}

.color-black-60 {
  color: rgba(0, 0, 0, 0.6);
}
