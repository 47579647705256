header {
  padding: 15px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  --width: 177px;
  --height: 25px;

  @include media-breakpoint-up(md) {
	--width: 249px;
	--height: 35px;
  }

  @include media-breakpoint-up(lg) {
	top: 10px;
	left: 30px;
	right: 30px;
  }


  .logo {
	display: inline-block;
	width: var(--width);
	height: var(--height);
	position: relative;


	img {
	  width: var(--width);
	  height: var(--height);
	  position: relative;
	}

	.light {
	  position: relative;
	  opacity: 0;
	  transition: opacity .5s ease;
	}

	.dark {
	  transition: opacity .5s ease;
	  position: absolute;
	  top: 0;
	  left: 0;
	}

	body.theme-dark & {
	  .light {
		opacity: 1;
	  }

	  .dark {
		opacity: 0;
	  }
	}

	@include media-breakpoint-down(md) {
	  body.menu-open & {
		.light {
		  opacity: 1;
		}

		.dark {
		  opacity: 0;
		}
	  }
	}

  }
}