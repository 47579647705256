.footer {
  background: #000;
  color: #FFF;

  a {
	color: #FFF;
	text-decoration: none;
  }

  padding: 40px 0;

  .title-footer {

	position: relative;
	text-transform: uppercase;
	font-size: 1.1rem;
	font-weight: 700;
	margin-bottom: .5rem;

	&:before {
	  position: relative;
	  content: '';
	  width: 20px;
	  height: 4px;
	  background-color: #FFF;
	  display: block;
	  margin-bottom: 1rem;
	}


  }

  address {
	font-size: .9rem;
	@include media-breakpoint-up(md) {
	  font-size: 1.2rem;
	}

	span {
	  font-weight: 600;
	}
  }

  p {
	font-weight: 700;
  }

  .rs {
	margin-bottom: 1rem;

	img {
	  width: 30px;
	  height: 30px;
	}
  }

  .part-2 {
	padding-top: 1rem;

	.line {
	  font-size: .9rem;
	  @include media-breakpoint-up(md) {
		font-size: 1.2rem;
	  }
	}
  }
}